import MerchantRepository from '../api/merchant';
import '../types';

class MerchantUseCase {
  constructor() {
    /**
         * @type {MerchantRepository}
         */
    this.api = new MerchantRepository();
  }
  /**
   * @param page {number}
   * @param perPage {number}
   * @return {Promise <{data: MERCHANT[], err: Error, pagination: pagination{}}>}
   */

  async getAll(page = 1, perPage = 40) {
    const queryParam = `?page=${page}&per_page=${perPage}`;
    const { data, err, pagination } = await this.api.getAll(queryParam);
    if (err) {
      return { data: null, err: null, pagination: null };
    }
    return { data, err, pagination };
  }

  /**
     *
     * @param {string | number} id
     * @returns {Promise<{data: Merchant, err: Error}>}
     */
  async getMerchant(id) {
    const { data, err } = await this.api.getMerchant(id);
    return { data, err };
  }

  /**
     *
     * @param {string | number} id
     * @returns {Promise<{data: ProductList, err: Error}>}
     */
  async getProducts(id) {
    const { data, err } = await this.api.getProducts(id);
    return { data, err };
  }

  /**
     * @param page {number}
     * @param perPage {number}
     * @return {Promise <{data: MERCHANT[], err: Error, pagination: pagination{}}>}
    */
  async getProductDetail(id) {
    const { data, err } = await this.api.getProductDetail(id);
    return { data, err };
  }
}

export default new MerchantUseCase();
