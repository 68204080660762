<template>
  <div
    class="lds-ellipsis"
    :class="colorClass"
    :style="{'left': positionLeft}"
  >
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script>
// source from https://loading.io/css/
export default {
  name: 'Loader',
  props: {
    colorClass: {
      type: String,
      default: 'l-white',
    },
    positionLeft: {
      type: String,
      default: '50%',
    },
  },
};
</script>

<style scoped lang="scss">
.l-white div {
  background: #fff;
}

.l-grey div {
  background: #4d4d4d;
}

.lds-ellipsis {
  position: absolute;
}

.lds-ellipsis div {
  position: absolute;
  left: -50%;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
