<template>
  <div class="allMerchant">
    <!-- Header -->
    <div class="allMerchant__header">
      <!-- BreadCrumb -->
      <BreadCrumb
        title="Semua Toko"
        class="allMerchant__breadcrumb"
      />
      <!-- BreadCrumb -->
      <!-- Banner Header -->
      <div class="allMerchant__header__banner">
        <img
          :src="bannerHeader"
          alt=""
        >
      </div>
      <div class="allMerchant__header__text">
        <h1>Banyak Tokonya, Banyak Diskonnya di <span>BisaBelanja</span></h1>
      </div>
      <!-- Banner Header -->
    </div>
    <!-- Header -->
    <div class="allMerchant__content">
      <div class="allMerchant__content__title">
        <h1>Semua Toko di <span>BisaBelanja</span></h1>
      </div>
      <!-- List of Merchant -->
      <Loader
        v-if="isLoading"
        color-class="l-grey"
      />
      <div class="allMerchant__content__merchant">
        <!-- Merchant Item -->
        <div
          v-for="(toko, index) in merchants"
          :key="index"
          :class="[`allMerchant__content__merchant__grid ` + toko.status]"
        >
          <div
            class="allMerchant__content__merchant__grid__item "
            @click="goToDetail(toko.merchant_name, toko.merchant_id)"
          >
            <div class="allMerchant__content__merchant__grid__item__img">
              <img
                :src="toko.image_url"
                alt="merchant-item"
              >
            </div>
            <div class="allMerchant__content__merchant__grid__item__name">
              {{ formatName(toko.merchant_name) }}
            </div>
            <div class="allMerchant__content__merchant__grid__item__address">
              {{ toko.city }}
            </div>
          </div>
        </div>
        <!-- Merchant Item -->
      </div>
      <!-- List of Merchant -->
      <!-- Pagination -->
      <div class="allMerchant__content__pagination">
        <Pagination
          v-model="pageRes"
          :total-all-item="pagination.total_item || 0"
          :per-page="perPage"
          :disable="true"
          @next="increment"
          @prev="decrement"
        />
      </div>
      <!-- Pagination -->
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Pagination from '@/components/new-pagination/Pagination.vue';
import API from '../app/usecase/merchant';
import Loader from '@/components/new-loader/Loader.vue';
import mixinMobile from '../../../misc/mixinMobile';
import mixinMetaInfo from '../../../misc/mixinMetaInfo';

export default {
  components: {
    BreadCrumb,
    Pagination,
    Loader,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  data() {
    return {
      carouselName: 'merchant',
      pageRes: 1,
      perPage: 40,
      isLoading: false,
      merchants: [],
      pagination: [],
    };
  },
  computed: {
    bannerHeader() {
      return this.windowWidth <= 699
        ? '/images/merchant/banner-header-mobile.webp'
        : '/images/merchant/banner-header-desktop.webp';
    },
  },
  created() {
    if (this.$route.query.page) {
      const page = Number(this.$route.query.page);
      this.fetchDataMerchant(page);
      this.pageRes = page;
    } else {
      this.fetchDataMerchant();
    }
  },
  methods: {
    increment() {
      this.pageRes++;
      this.fetchDataMerchant(this.pageRes);
      window.history.replaceState(null, null, `?page=${this.pageRes}&per_page=${this.perPage}`);
      window.scrollTo(100, 100);
    },
    decrement() {
      if (this.pageRes > 1) {
        this.pageRes--;
        this.fetchDataMerchant(this.pageRes);
        window.history.replaceState(null, null, `?page=${this.pageRes}&per_page=${this.perPage}`);
        window.scrollTo(100, 100);
      }
    },
    async fetchDataMerchant(page) {
      this.isLoading = true;
      const data = await API.getAll(page);
      if (!data.err) {
        this.merchants = data.data.data;
        this.pagination = data.pagination;
      }
      this.isLoading = false;
    },
    goToDetail(name, id) {
      const merchant = name.split(' ').join('-').toLowerCase();
      const slug = encodeURIComponent(`${merchant}-${id}`);
      this.$router.push(`/merchant/detail/${slug}`);
    },
    formatName(string) {
      return string
        .split(' ')
        .map(
          val => (val.length < 15
            ? val
            : (
              `${val.substring(0, 15)}...`
            )),
        )
        .join(' ');
    },
  },
  metaInfo() {
    const SEOData = {
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    };
    return this.SEO(SEOData);
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
