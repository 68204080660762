import CustomerAppAPI from '@/app/customerAppAxios';
import '../types';
/**
 * @borrows CustomerAppAPI
 */
export default class MerchantRepository extends CustomerAppAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/shop/merchant';
    this._endpointMerchant = 'v2/shop/merchant/';
    this._endpointProducts = 'v1/shop/merchant/';
    this._endpointProductDetail = 'v1/shop/product/';
  }

  async getAll(queryParam) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}${queryParam}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null, pagination: res.data.pagination };
        }
      }
      return { data: null, err: null, pagination: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.message), pagination: null };
    }
  }

  /**
   *
   * @param param {string | number} merchant id
   * @returns {Promise<{data: Merchant | null, err: string | null}>}
   */
  async getMerchant(param) {
    try {
      const res = await this.$axios.get(this._endpointMerchant + param);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (error) {
      return { data: null, err: this.setErrorMessage(error) };
    }
  }

  /**
   *
   * @param param {string | number} merchant id
   * @returns {Promise<{data: ProductList | [], err: string | null}>}
   */
  async getProducts(param) {
    try {
      const res = await this.$axios.get(`${this._endpointProducts + param}/product`);
      if (res.status === 200) {
        if (res.data.data) {
          return { data: res.data.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (error) {
      return { data: null, err: this.setErrorMessage(error) };
    }
  }

  /**
   *
   * @param param {string | number} product id
   * @return {Promise<{data: Product, err: string | null}>}
  */
  async getProductDetail(param) {
    try {
      const res = await this.$axios.get(this._endpointProductDetail + param);
      if (res.status === 200 && res.data) {
        return { data: res.data, err: null };
      }
      return { data: null, err: res };
    } catch (error) {
      return { data: null, err: this.setErrorMessage(error) };
    }
  }
}
